import React, { Fragment, useCallback, useEffect, useReducer, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Dropdown, Segment } from "semantic-ui-react";
import rankings from "../../apis/ranking";
import cruises from "../../apis/cruises";
import yachts from "../../apis/yachts";
import LoadingError from "../common/LoadingError";
import Statistics, { PEOPLE } from "../common/Statistics";
import './RankingPage.css';
import { useSearchParams } from "react-router-dom";
import { useQuery } from "../../hooks/Query";
import RankingList from "./RankingList";
import { getWithCaptainsCookie, setWithCaptainsCookie } from "../../utils/cookieUtils";

const DEFAULT_SIZE = 10;
// const MAX_SIZE = 100;
const FIRST_PAGE = 1;

const PAGE_CONTROL_CLICKED = "PAGE_CONTROL_CLICKED";
const DEBOUNCED_QUERY_CHANGED = "DEBOUNCED_QUERY_CHANGED";
const RANKING_TYPE_CHANGED = "RANKING_TYPE_CHANGED";
const CAPTAINS_CLICKED = "CAPTAINS_CLICKED";

const RankingType = {
  GENERAL: "GENERAL",
  YEARLY: "YEARLY",
  CRUISE: "CRUISE",
  YACHT: "YACHT",
  DATE: "DATE"
}

const reducer = (state, [type, payload]) => {

  const oldState = {...state, queryChanged: false, rankingTypeChanged: false, captainsChanged: false};

  switch (type) {

    case PAGE_CONTROL_CLICKED:
      return {...oldState, pageNumber: payload};

    case CAPTAINS_CLICKED:
      return {...oldState, pageNumber: FIRST_PAGE, captains: payload, captainsChanged: true};

    case DEBOUNCED_QUERY_CHANGED:
      let changes = {debouncedQuery: payload};
      if (state.lastQuery !== payload) {
        changes.pageNumber = FIRST_PAGE;
      }
      changes.lastQuery = payload;
      return {...oldState, ...changes, queryChanged: true};

    case RANKING_TYPE_CHANGED: 
      return {...oldState, pageNumber: FIRST_PAGE, type: payload.type, param: payload.param, rankingTypeChanged: true};

    default:
      return oldState;
  }
}

const RankingPage = () => {

  const [searchParams] = useSearchParams();
  
  const type = searchParams.get('type');
  const year = searchParams.get('year');
  const cruise = searchParams.get('cruise');
  const yacht = searchParams.get('yacht');
  const date = searchParams.get('date');

  const resolveType = useCallback(() => {
    if (year) {
      return {type: RankingType.YEARLY, param: year};
    }
    if (cruise) {
      return {type: RankingType.CRUISE, param: cruise};
    }
    if (yacht) {
      return {type: RankingType.YACHT, param: yacht};
    }
    if (date) {
      return {type: RankingType.DATE, param: date};
    }

    return {type: RankingType.GENERAL, param: undefined};
  
  }, [year, cruise, yacht, date]);
  
  const initialState = {
    pageNumber: FIRST_PAGE,
    pageSize: DEFAULT_SIZE,
    debouncedQuery: '',
    lastQuery: '',
    captains: getWithCaptainsCookie(true),
    ...resolveType()
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  
  const {debouncedQuery, query, setQuery} = useQuery('');
  
  const [ ranking, setRanking ] = useState({
    name: "",
    pageNumber: 0,
    totalPagesCount: 0,
    totalItems: 0,
    items: [],
    stats: {}, 
    meta: {},
    query: '',
    captainsChanged: false,
    queryChanged: false,
    rankingTypeChanged: false
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState("");
  const [rankingName, setRankingTitle] = useState("");
  const [ranksData, setRanksData] = useState([]);

  useEffect(() => {

    const goToYear = year => {
      dispatch([RANKING_TYPE_CHANGED, {type: RankingType.YEARLY, param: year}]);
    };
  
    const getDropdown = (year, rankedYears) => {
      return (
        <Fragment>
          &nbsp;&nbsp;
          <Dropdown 
            id="milesrank-year-select"
            search
            selection
            value={year}
            options={rankedYears}
            onChange={(e, { value }) =>  goToYear(value)}
          />
        </Fragment>
      );
    };

    const prepareCruiseRanking = (cruiseId, pageNumber, pageSize, captains, query) => {
      console.log('prepareCruiseRanking', {pageNumber, pageSize, captains, query, cruiseId});
      cruises.getById(cruiseId).then(({data}) => 
        setRankingTitle(<FormattedMessage id="title.ranking.cruise" defaultMessage="Ranking after {cruise}" values={{cruise: data.name}} />))

      return rankings.getCruiseRankingPage(pageNumber, pageSize, cruiseId, captains, query);
    };

    const prepareYearRanking = (year, pageNumber, pageSize, captains, query) => {
      console.log('prepareYearRanking', {pageNumber, pageSize, captains, query, year});
      cruises.getYears()
        .then(({data}) => {
          setRankingTitle(<FormattedMessage 
            id="title.ranking.year" 
            defaultMessage="Yearly ranking {year}" 
            values={{year: getDropdown(year, data.years.map(y => ({key: y, value: `${y}`, text: `${y}`})))}} />);
        });

      return rankings.getYearRankingPage(pageNumber, pageSize, year, captains, query);
    };

    const prepareYachtRanking = (yachtId, pageNumber, pageSize, captains, query) => {
      console.log('prepareYachtRanking', {pageNumber, pageSize, captains, query, yachtId});
      yachts.getById(yachtId).then(({data}) => 
          setRankingTitle(<FormattedMessage id="title.ranking.yacht" defaultMessage="Crew of {yacht} ranking" values={{yacht: data.name}} />))

      return rankings.getYachtCrewRankingPage(pageNumber, pageSize, yachtId, captains, query);
    };

    const prepareGeneralRanking = (pageNumber, pageSize, captains, query) => {
      console.log('prepareGeneralRanking', {pageNumber, captains, pageSize, query});
      setRankingTitle(<FormattedMessage id="title.ranking.general" defaultMessage="General ranking" />)
      return rankings.getGeneralRankingPage(pageNumber, pageSize, captains, query);
    };

    const resolveRankingRequest = (type, param, pageNumber, pageSize, captains, debouncedQuery) => {
        switch (type) {
          case RankingType.YEARLY:
            return prepareYearRanking(param, pageNumber, pageSize, captains, debouncedQuery);
          
          case RankingType.CRUISE:
            return prepareCruiseRanking(param, pageNumber, pageSize, captains, debouncedQuery);

          case RankingType.YACHT:
            return prepareYachtRanking(param, pageNumber, pageSize, captains, debouncedQuery);  

          default:
            return prepareGeneralRanking(pageNumber, pageSize, captains, debouncedQuery);
        }
    };
    
    const loadRanking = () => {

      setPageLoading(true);

      const request = resolveRankingRequest(state.type, state.param, state.pageNumber, state.pageSize, state.captains, state.debouncedQuery);

      request
        .then((response) => {
          setRanking({...response.data, meta: {type: state.type, param: state.param, query: state.debouncedQuery, captains: state.captains}});
          setLoadingError(false);
          setLoadingErrorMessage("");
        })
        .catch((error) => {
          console.log(error);
          setLoadingError(true);
          setLoadingErrorMessage(
            <FormattedMessage
              id="error.loading.ranking"
              defaultMessage="Whoops! Something went wrong :("
            />
          );
        })
        .finally(() => {
          setPageLoading(false);
        });
    };

    // console.log('loading ranking, state', state);
    loadRanking();

  }, [
    state.pageNumber,
    state.pageSize,
    state.debouncedQuery,
    state.type,
    state.param, 
    state.captains
  ]);

  useEffect(() => {
    console.log('debouncedQuery', debouncedQuery)
    dispatch([DEBOUNCED_QUERY_CHANGED, debouncedQuery]);
  }, [debouncedQuery]);

  useEffect(() => {
    // console.log('state', state);
    console.log('params', {type, year, cruise, yacht, date});
    dispatch([RANKING_TYPE_CHANGED, resolveType()]);
  }, [type, year, cruise, yacht, date, resolveType]);

  const onPageChange = (event, pagination) => {
    console.log('onPageChange', pagination.activePage)
    dispatch([PAGE_CONTROL_CLICKED, pagination.activePage]);
  };
  
  const onCaptainsClicked = (value) => {
    setWithCaptainsCookie(value);
    dispatch([CAPTAINS_CLICKED, value]);
  };

  const getComparison = items => {
    setPageLoading(true);
    rankings.getMultiRanks(items, state.captains)
      .then(response => {
        console.log('getMultiRanks', response.data.peopleRanks);
        setRanksData(response.data.peopleRanks);
      })
      .finally(() => {
        setPageLoading(false);
      })
  };

  const shouldShowStatsLoader = pageLoading && state.pageNumber === 1 && state.rankingTypeChanged;
  const shouldShowListLoader = pageLoading && state.pageNumber === 1;

  return (
    <div>
      <h1 className="ui header">
        {rankingName}
      </h1>

      {loadingError ? (
        <LoadingError message={loadingErrorMessage} />
      ) : null}

      <Segment>
        <Statistics stats={ranking.stats} excludes={[PEOPLE]} loading={shouldShowStatsLoader} />
      </Segment>

      <RankingList
        ranking={ranking}
        search={query || ''}
        onPageChange={onPageChange}
        onSearchInputChange={(event, { value }) => setQuery(value)}
        onCompareClick={getComparison}
        loading={shouldShowListLoader}
        ranksData={ranksData}
        onCaptainsClicked={onCaptainsClicked}
      />
    </div>
  );
}

export default RankingPage;
