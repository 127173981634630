import Cookies from 'js-cookie';

function getCookie(name) {
  return Cookies.get(name);
}

function setCookie(name, value, params) {
  return Cookies.set(name, value, params);
}

/**
 * @returns {boolean} 
 */
export function getWithCaptainsCookie(defaultValue) {
  let value = getCookie('wcptn');
  if (value === undefined) {
    return defaultValue;
  }
  return value === '1';
}

/**
 * @param {boolean} withCaptains 
 * @returns 
 */
export function setWithCaptainsCookie(withCaptains) {
  return setCookie('wcptn', withCaptains ? '1' : '0', { expires: 365 });
}