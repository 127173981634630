import React from "react";

class VesselFinderMap extends React.Component {
  createVesselFinderUrl(params) {
    return ( // TODO: make it nicer :)
      "https://www.vesselfinder.com/aismap?" +
      "zoom=" +
      (params.zoom === undefined ? "undefined" : encodeURIComponent(params.zoom)) +
      (params.latitude === undefined
        ? "&lat=undefined"
        : "&lat=" + encodeURIComponent(params.latitude)) +
      (params.longitude === undefined
        ? "&lon=undefined"
        : "&lon=" + encodeURIComponent(params.longitude)) +
      "&width=" +
      encodeURIComponent(params.width) +
      "&height=" +
      encodeURIComponent(params.height) +
      "&names=" +
      params.names +
      (params.mmsi === undefined ? "" : "&mmsi=" + encodeURIComponent(params.mmsi)) +
      (params.imo === undefined ? "" : "&imo=" + encodeURIComponent(params.imo)) +
      "&track=" +
      encodeURIComponent(params.showTrack) +
      // + ((params.fleet === undefined) ? '&fleet=false' : '&fleet='+params.fleet)
      // + ((params.fleetName === undefined) ? '&fleet_name=false' : '&fleet_name='+params.fleetName)
      // + ((params.fleetTimespan !== undefined) ? '&fleet_timespan=' + params.fleetTimespan : '')
      // + ((params.fleetHideOldPositions === undefined) ? '&fleet_hide_old_positions=false' : '&fleet_hide_old_positions='+params.fleetHideOldPositions)
      "&clicktoact=" +
      encodeURIComponent(params.clickToActivate) +
      "&store_pos=" +
      encodeURIComponent(params.storePosition) +
      (params.fil === undefined ? "" : "&fil=" + encodeURIComponent(params.fil)) +
      (params.defaultMaptype === undefined
        ? ""
        : "&default_maptype=" + encodeURIComponent(params.defaultMaptype)) +
      "&ra=" +
      encodeURIComponent(window.location.href)
    );
  }

  render() {
    return (
      <div>
        <iframe
          name="vesselfinder"
          title="VesselFinder"
          id="vesselfinder"
          width={this.props.params.width}
          height={this.props.params.height}
          frameBorder="0"
          src={this.createVesselFinderUrl(this.props.params)}
        >
          Browser does not support embedded objects.
          <br />
          Visit directly{" "}
          <a
            href="https://www.vesselfinder.com"
            rel="noreferrer"
            target="_blank"
          >
            www.vesselfinder.com
          </a>
        </iframe>
      </div>
    );
  }
}

export default VesselFinderMap;
